@font-face {
    font-family: 'Effra';
    src: url('assets/fonts/Effra-LightItalic.eot');
    src: local('Effra Light Italic'), local('Effra-LightItalic'),
    url('assets/fonts/Effra-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Effra-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Effra';
    src: url('assets/fonts/Effra-Bold.eot');
    src: local('Effra Bold'), local('Effra-Bold'),
    url('assets/fonts/Effra-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Effra-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Effra';
    src: url('assets/fonts/Effra-Medium.eot');
    src: local('Effra Medium'), local('Effra-Medium'),
    url('assets/fonts/Effra-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Effra-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Effra';
    src: url('assets/fonts/Effra-Light.eot');
    src: local('Effra Light'), local('Effra-Light'),
    url('assets/fonts/Effra-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Effra-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Effra';
    src: url('assets/fonts/Effra-BoldItalic.eot');
    src: local('Effra Bold Italic'), local('Effra-BoldItalic'),
    url('assets/fonts/Effra-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Effra-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Effra';
    src: url('assets/fonts/Effra-MediumItalic.eot');
    src: local('Effra Medium Italic'), local('Effra-MediumItalic'),
    url('assets/fonts/Effra-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Effra-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
